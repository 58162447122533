import React from "react"
import styled from "@emotion/styled"

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`

export function Footer() {
  return (
    <FooterContainer>
      © {new Date().getFullYear()}, Built with &nbsp;
      <a href="https://www.gatsbyjs.org"> Gatsby</a>
    </FooterContainer>
  )
}
