import styled from "@emotion/styled"

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.97);
`
export const Title = styled.div`
  padding-left: 1.3rem;
  display: flex;
  flex-grow: 1;

  & h1 {
    text-transform: capitalize;
    margin-right: 0.3rem;
  }
`
export const EmoticonContainer = styled.div`
  width: 2rem;
`
export const MenuButton = styled.div`
  visibility: hidden;
  justify-self: flex-end;
  padding-right: 0.7rem;

  @media (max-width: 1290px) {
    visibility: visible;
  }
`
export const Button = styled.button`
  padding: 0;
  background: none;
  border: none;
  outline: none;
  width: 1.8rem;

  & :hover {
    cursor: pointer;
  }
`
