import React from "react"
import {
  Html,
  Css,
  Js,
  Ts,
  ReactIco,
  Gatsby,
  Git,
  Figma,
  VsCode,
  StyledComponents,
  Twitter,
  Linkedin,
  Mail,
  Github,
  Menu,
  Close
} from "./svgs"

const Icon = ({ name }) => {
  switch (name) {
    case "html":
      return <Html />
    case "css":
      return <Css />
    case "js":
      return <Js />
    case "ts":
      return <Ts />
    case "react":
      return <ReactIco />
    case "gatsby":
      return <Gatsby />
    case "git":
      return <Git />
    case "vsc":
      return <VsCode />
    case "figma":
      return <Figma />
    case "sc":
      return <StyledComponents />
    case "twitter":
      return <Twitter />
    case "linkedin":
      return <Linkedin />
    case "mail":
      return <Mail />
    case "github":
      return <Github />
    case "menu":
      return <Menu />
    case "close":
      return <Close />
    default:
      return <p>Error</p>
  }
}

export default Icon
