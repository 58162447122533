import React from "react"
import { ADN, Files, Girl, GraduateHat, HandWave, Phone } from "./svgs"

const Emoticon = ({ name }) => {
  switch (name) {
    case "ADN":
      return <ADN />
    case "files":
      return <Files />
    case "girl":
      return <Girl />
    case "hat":
      return <GraduateHat />
    case "hello":
      return <HandWave />
    case "phone":
      return <Phone />
    default:
      return <p>Error</p>
  }
}

export default Emoticon
