import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Header } from "./Header"
import { Footer } from "./Footer"
import { Menu } from "../menu"
import "./global.css"

const Container = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 16fr 0.5fr;
`

const Layout = ({ children }) => {
  const [state, setState] = useState(false)

  return (
    <Container id="home">
      {state ? <Menu setState={setState} /> : null}
      <Header setState={setState} />
      {children}
      <Footer />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
