import styled from "@emotion/styled"

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(114, 52, 198, 0.95);
`
export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 35vh;
  width: 80vw;
  margin: 20vh auto;
`
export const LinkList = styled.ul`
  padding: 0;
  text-align: center;
  list-style: none;
  & button {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  }
`
export const NavLink = styled.li`
  margin: 1rem 0;
  & a {
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
  }
`
