import React from "react"
import Emoticon from "../../../utils/emoticons"
import Icon from "../../../utils/icons"
import {
  HeaderContainer,
  Title,
  EmoticonContainer,
  MenuButton,
  Button
} from "./headerCss"

export function Header({ setState }) {
  const handleClick = () => {
    setState(true)
  }

  return (
    <HeaderContainer>
      <Title>
        <h1>Flora</h1>
        <EmoticonContainer>
          <Emoticon name="girl" />
        </EmoticonContainer>
      </Title>
      <MenuButton>
        <Button id="menubutton" aria-label="Open Menu" onClick={handleClick}>
          <Icon name="menu" />
        </Button>
      </MenuButton>
    </HeaderContainer>
  )
}
