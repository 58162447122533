import React from "react"
import Icon from "../../utils/icons"
import { MenuContainer, ContentContainer, LinkList, NavLink } from "./menuCss"
import { Link } from "gatsby"

export function Menu({ setState }) {
  const handleClick = e => {
    debugger
    console.log(e)
    setState(false)
  }

  return (
    <MenuContainer>
      <ContentContainer>
        <LinkList id="menu">
          <button onClick={handleClick} aria-label="Close menu">
            <Icon name="close" />
          </button>
          <div>
            <NavLink role="none">
              <Link to="#home" onClick={handleClick}>
                Home
              </Link>
            </NavLink>
            <NavLink role="none">
              <Link to="#projects" onClick={handleClick}>
                Projects
              </Link>
            </NavLink>
            <NavLink role="none">
              <Link to="#about" onClick={handleClick}>
                About
              </Link>
            </NavLink>
            <NavLink role="none">
              <Link to="#skills" onClick={handleClick}>
                Skills
              </Link>
            </NavLink>
            <NavLink role="none">
              <Link to="#contact" onClick={handleClick}>
                Contact
              </Link>
            </NavLink>
          </div>
        </LinkList>
      </ContentContainer>
    </MenuContainer>
  )
}
