import React from "react"

export const Girl = () => {
  return (
    <svg viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5278 0C17.759 0 16.0919 0.428555 14.6017 1.17272C14.1977 1.10094 13.7846 1.05556 13.3611 1.05556C9.38774 1.05556 6.16669 4.36367 6.16669 8.44444V21.1111H6.18519C6.37327 27.9374 11.8082 33.4147 18.5 33.4147C25.1919 33.4147 30.6268 27.9374 30.8149 21.1111H30.8334V11.6111C30.8334 5.19861 25.7715 0 19.5278 0Z"
        fill="#FFAC33"
      />
      <path
        d="M32.8889 6.99311L29.8056 5.27783L27.75 6.99311C27.75 6.99311 30.8333 14.6459 30.8333 18.7362C30.8333 22.8264 27.75 28.5001 27.75 28.5001H9.25C9.25 28.5001 6.16667 22.8264 6.16667 18.7362C6.16667 14.6459 9.25 6.99311 9.25 6.99311L7.19444 5.27783L4.11111 6.99311C4.11111 6.99311 0 14.5139 0 18.7362C0 22.9584 6.16667 38.0001 6.16667 38.0001H30.8333C30.8333 38.0001 37 22.9584 37 18.7362C37 14.5139 32.8889 6.99311 32.8889 6.99311Z"
        fill="#9268CA"
      />
      <path
        d="M14.3889 5.27783C14.3889 5.27783 14.3858 10.8807 9.25 11.5436V17.9445C9.25 22.0791 11.8266 25.5867 15.4167 26.8924V29.5556C15.4167 31.3047 16.797 32.7223 18.5 32.7223C20.203 32.7223 21.5833 31.3047 21.5833 29.5556V26.8924C25.1734 25.5867 27.75 22.0791 27.75 17.9445V12.6467C16.3314 12.2498 14.3889 5.27783 14.3889 5.27783Z"
        fill="#FFDC5D"
      />
      <path
        d="M22.6111 22.1666H14.3889C14.3889 22.1666 15.4167 24.2777 18.5 24.2777C21.5834 24.2777 22.6111 22.1666 22.6111 22.1666Z"
        fill="#DF1F32"
      />
      <path
        d="M19.5278 20.5834H17.4722C17.1885 20.5834 16.9583 20.3469 16.9583 20.0556C16.9583 19.7643 17.1885 19.5278 17.4722 19.5278H19.5278C19.8114 19.5278 20.0416 19.7643 20.0416 20.0556C20.0416 20.3469 19.8114 20.5834 19.5278 20.5834Z"
        fill="#C1694F"
      />
      <path
        d="M14.3889 16.8888C13.8215 16.8888 13.3611 16.4159 13.3611 15.8333V14.7777C13.3611 14.1951 13.8215 13.7222 14.3889 13.7222C14.9562 13.7222 15.4166 14.1951 15.4166 14.7777V15.8333C15.4166 16.4159 14.9562 16.8888 14.3889 16.8888ZM22.6111 16.8888C22.0427 16.8888 21.5833 16.4159 21.5833 15.8333V14.7777C21.5833 14.1951 22.0427 13.7222 22.6111 13.7222C23.1794 13.7222 23.6389 14.1951 23.6389 14.7777V15.8333C23.6389 16.4159 23.1794 16.8888 22.6111 16.8888Z"
        fill="#662113"
      />
      <path
        d="M19.7744 2.52911C19.8176 1.97917 20.3839 1.76173 20.9523 1.78178C20.9523 1.78178 26.5598 2.11006 28.6822 3.12339C31.3698 4.40589 32.8889 6.99306 32.8889 6.99306H27.75C27.75 6.99306 26.6369 5.38756 25.7581 4.75C24.8527 4.09239 21.4189 3.4675 20.9081 3.36511C20.3973 3.26273 19.7313 3.07906 19.7744 2.52911ZM17.2255 2.52911C17.1824 1.97917 16.6161 1.76173 16.0477 1.78178C16.0477 1.78178 10.4401 2.11006 8.31778 3.12339C5.63014 4.40589 4.11108 6.99306 4.11108 6.99306H9.24997C9.24997 6.99306 10.3631 5.38756 11.2418 4.75C12.1473 4.09239 15.5811 3.4675 16.0919 3.36511C16.6017 3.26167 17.2687 3.07906 17.2255 2.52911V2.52911Z"
        fill="#FFDC5D"
      />
    </svg>
  )
}

export const Files = () => {
  return (
    <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38 21.2353C38 23.7041 35.9983 23.4706 33.5294 23.4706H4.47059C2.00171 23.4706 0 23.7041 0 21.2353L2.23529 7.82349C2.375 5.68096 4.237 3.35291 6.70588 3.35291H31.2941C33.763 3.35291 35.5311 5.82067 35.7647 7.82349L38 21.2353Z"
        fill="#67757F"
      />
      <path
        d="M30.1765 13.4118C30.1765 14.6457 29.1751 15.6471 27.9412 15.6471H10.0588C8.82496 15.6471 7.82355 14.6457 7.82355 13.4118V4.47065C7.82355 3.23565 8.82496 2.23535 10.0588 2.23535H27.9412C29.1751 2.23535 30.1765 3.23565 30.1765 4.47065V13.4118Z"
        fill="#CCD6DD"
      />
      <path
        d="M32.4118 17.8824C32.4118 19.1163 31.4104 20.1177 30.1765 20.1177H7.82355C6.58967 20.1177 5.58826 19.1163 5.58826 17.8824V8.94123C5.58826 7.70734 6.58967 6.70593 7.82355 6.70593H30.1765C31.4104 6.70593 32.4118 7.70734 32.4118 8.94123V17.8824Z"
        fill="#E1E8ED"
      />
      <path
        d="M34.6471 22.353C34.6471 23.5869 33.6457 24.5883 32.4118 24.5883H5.58826C4.35438 24.5883 3.35297 23.5869 3.35297 22.353V13.4118C3.35297 12.1779 4.35438 11.1765 5.58826 11.1765H32.4118C33.6457 11.1765 34.6471 12.1779 34.6471 13.4118V22.353Z"
        fill="#F5F8FA"
      />
      <path
        d="M38 33.5294C38 35.9982 35.9983 37.9999 33.5294 37.9999H4.47059C2.00171 37.9999 0 35.9982 0 33.5294V21.2352C0 18.7664 2.00171 16.7646 4.47059 16.7646H33.5294C35.9983 16.7646 38 18.7664 38 21.2352V33.5294Z"
        fill="#9AAAB4"
      />
      <path
        d="M23.4706 0H17.8823C16.6484 0 15.647 1.00029 15.647 2.23529V3.35294H20.1176C21.3515 3.35294 22.3529 4.35435 22.3529 5.58824H23.4706C24.7044 5.58824 25.7059 4.58682 25.7059 3.35294V2.23529C25.7059 1.00029 24.7044 0 23.4706 0Z"
        fill="#FCAB40"
      />
      <path
        d="M19 4.47058H13.4117C12.1779 4.47058 11.1765 5.47199 11.1765 6.70588V7.82352H15.647C16.8809 7.82352 17.8823 8.82493 17.8823 10.0588H19C20.2339 10.0588 21.2353 9.0574 21.2353 7.82352V6.70588C21.2353 5.47199 20.2339 4.47058 19 4.47058Z"
        fill="#5DADEC"
      />
      <path
        d="M16.7647 12.2941C16.7647 13.528 15.7633 14.5294 14.5294 14.5294H8.94117C7.70728 14.5294 6.70587 13.528 6.70587 12.2941V11.1765C6.70587 9.94257 7.70728 8.94116 8.94117 8.94116H14.5294C15.7633 8.94116 16.7647 9.94257 16.7647 11.1765V12.2941Z"
        fill="#E75A70"
      />
      <path
        d="M26.8235 30.1764C26.8235 31.4103 25.8221 32.4117 24.5882 32.4117H13.4117C12.1779 32.4117 11.1765 31.4103 11.1765 30.1764V24.5882C11.1765 23.3543 12.1779 22.3529 13.4117 22.3529H24.5882C25.8221 22.3529 26.8235 23.3543 26.8235 24.5882V30.1764Z"
        fill="#67757F"
      />
      <path
        d="M24.5882 29.0588C24.5882 29.6769 24.0886 30.1765 23.4706 30.1765H14.5294C13.9124 30.1765 13.4117 29.6769 13.4117 29.0588V25.7059C13.4117 25.0878 13.9124 24.5883 14.5294 24.5883H23.4706C24.0886 24.5883 24.5882 25.0878 24.5882 25.7059V29.0588Z"
        fill="#E1E8ED"
      />
    </svg>
  )
}

export const HandWave = () => {
  return (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.861 9.10499C5.801 8.44799 7.218 8.57399 8.062 9.27099L7.094 7.86399C6.315 6.75299 6.594 5.55099 7.706 4.77099C8.818 3.99399 11.969 6.08299 11.969 6.08299C11.183 4.96099 11.33 3.53899 12.452 2.75199C13.574 1.96799 15.122 2.23899 15.908 3.36299L26.328 18.083L25 30.958L13.917 26.916L4.25 12.583C3.457 11.454 3.731 9.89699 4.861 9.10499Z"
        fill="#D2A077"
      />
      <path
        d="M2.695 17.294C2.695 17.294 1.563 15.644 3.214 14.513C4.863 13.382 5.994 15.031 5.994 15.031L11.245 22.689C11.426 22.387 11.624 22.089 11.845 21.795L4.557 11.168C4.557 11.168 3.426 9.51897 5.076 8.38797C6.725 7.25697 7.856 8.90597 7.856 8.90597L14.711 18.903C14.966 18.695 15.227 18.486 15.496 18.281L7.549 6.68997C7.549 6.68997 6.418 5.04097 8.068 3.90997C9.717 2.77897 10.848 4.42797 10.848 4.42797L18.795 16.017C19.087 15.838 19.376 15.683 19.666 15.519L12.238 4.68697C12.238 4.68697 11.107 3.03797 12.756 1.90697C14.405 0.775966 15.536 2.42497 15.536 2.42497L23.39 13.879L24.584 15.621C19.636 19.015 19.165 25.4 21.992 29.523C22.557 30.348 23.382 29.783 23.382 29.783C19.989 24.834 21.025 19.273 25.974 15.88L24.515 8.57797C24.515 8.57797 23.97 6.65397 25.893 6.10797C27.817 5.56297 28.363 7.48697 28.363 7.48697L30.048 12.491C30.716 14.475 31.427 16.452 32.368 18.322C35.025 23.602 33.438 30.164 28.428 33.601C22.963 37.348 15.492 35.955 11.744 30.491L2.695 17.294Z"
        fill="#F3D2A2"
      />
      <path
        d="M12 32C7.99999 32 3.95799 27.958 3.95799 23.958C3.95799 23.405 3.55299 22.958 2.99999 22.958C2.44699 22.958 1.95799 23.405 1.95799 23.958C1.95799 29.958 5.99999 34 12 34C12.553 34 13 33.511 13 32.958C13 32.405 12.553 32 12 32Z"
        fill="#5DADEC"
      />
      <path
        d="M7 33.958C4 33.958 2 31.958 2 28.958C2 28.405 1.553 27.958 1 27.958C0.447 27.958 0 28.405 0 28.958C0 32.958 3 35.958 7 35.958C7.553 35.958 8 35.511 8 34.958C8 34.405 7.553 33.958 7 33.958ZM24 1.95801C23.448 1.95801 23 2.40601 23 2.95801C23 3.51001 23.448 3.95801 24 3.95801C28 3.95801 32 7.54701 32 11.958C32 12.51 32.448 12.958 33 12.958C33.552 12.958 34 12.51 34 11.958C34 6.44401 30 1.95801 24 1.95801Z"
        fill="#5DADEC"
      />
      <path
        d="M29 0C28.448 0 28 0.406 28 0.958C28 1.51 28.448 2 29 2C32 2 33.958 4.225 33.958 6.958C33.958 7.51 34.447 7.958 35 7.958C35.553 7.958 35.958 7.51 35.958 6.958C35.958 3.121 33 0 29 0Z"
        fill="#5DADEC"
      />
    </svg>
  )
}

export const Phone = () => {
  return (
    <svg viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38 9.10714C38 5.41814 34.8333 0 19 0C3.16667 0 0 5.41814 0 9.10714C0 9.16179 0.0200556 9.19943 0.0232222 9.25164C0.0105556 9.48964 0 9.73979 0 10.0179C0 13.2029 2.36339 14.5714 5.27778 14.5714C8.19217 14.5714 10.5556 13.2029 10.5556 10.0179C10.5556 9.75314 10.5334 9.51757 10.5017 9.29293C13.0614 8.86671 15.941 8.5 19 8.5C22.059 8.5 24.9386 8.86671 27.4983 9.29293C27.4666 9.51757 27.4444 9.75314 27.4444 10.0179C27.4444 13.2029 29.8068 14.5714 32.7222 14.5714C35.6377 14.5714 38 13.2029 38 10.0179C38 9.74464 37.9905 9.49693 37.9747 9.26379C37.9789 9.20793 38 9.16664 38 9.10714Z"
        fill="#BE1931"
      />
      <path
        d="M36.8748 23.0714C36.366 21.8195 34.4364 17.9835 32.6863 14.5629C31.0418 11.3487 28.5 8.49997 26.3889 8.49997V6.07139C26.3889 4.73082 25.4431 3.64282 24.2778 3.64282C23.1124 3.64282 22.1667 4.73082 22.1667 6.07139V8.49997H15.8333V6.07139C15.8333 4.73082 14.8876 3.64282 13.7222 3.64282C12.5569 3.64282 11.6111 4.73082 11.6111 6.07139V8.49997C9.5 8.49997 6.95611 11.3548 5.30944 14.5714C3.56039 17.9896 1.63294 21.8207 1.12522 23.0714C0.515111 24.5759 0 25.5 0 27.9285C0 30.3571 1.8905 34 4.22222 34H33.7778C36.1095 34 38 30.3571 38 27.9285C38 25.5 37.5271 24.6791 36.8748 23.0714Z"
        fill="#DD2E44"
      />
      <path
        d="M21.1597 13.1361C21.1597 13.685 20.1928 14.1294 19 14.1294C17.8072 14.1294 16.8414 13.685 16.8414 13.1361C16.8414 12.5873 17.8072 12.1428 19 12.1428C20.1928 12.1428 21.1597 12.5873 21.1597 13.1361ZM15.9769 13.1361C15.9769 13.685 15.0111 14.1294 13.8183 14.1294C12.6255 14.1294 11.6597 13.685 11.6597 13.1361C11.6597 12.5873 12.6266 12.1428 13.8183 12.1428C15.01 12.1428 15.9769 12.5873 15.9769 13.1361ZM26.3404 13.1361C26.3404 13.685 25.3745 14.1294 24.1817 14.1294C22.989 14.1294 22.0221 13.685 22.0221 13.1361C22.0221 12.5873 22.989 12.1428 24.1817 12.1428C25.3745 12.1428 26.3404 12.5873 26.3404 13.1361ZM21.5904 16.6138C21.5904 17.4383 20.4303 18.105 19 18.105C17.5697 18.105 16.4086 17.4383 16.4086 16.6138C16.4086 15.7917 17.5687 15.1239 19 15.1239C20.4314 15.1239 21.5904 15.7905 21.5904 16.6138ZM27.6366 16.6138C27.6366 17.4383 26.4755 18.105 25.0462 18.105C23.616 18.105 22.4549 17.4383 22.4549 16.6138C22.4549 15.7917 23.616 15.1239 25.0462 15.1239C26.4755 15.1239 27.6366 15.7905 27.6366 16.6138ZM15.5452 16.6138C15.5452 17.4383 14.3851 18.105 12.9549 18.105C11.5246 18.105 10.3635 17.4383 10.3635 16.6138C10.3635 15.7917 11.5235 15.1239 12.9549 15.1239C14.3851 15.1239 15.5452 15.7905 15.5452 16.6138ZM22.4538 21.0836C22.4538 22.1838 20.9074 23.0714 19 23.0714C17.0926 23.0714 15.5452 22.1838 15.5452 21.0836C15.5452 19.9871 17.0926 19.097 19 19.097C20.9074 19.0983 22.4538 19.9871 22.4538 21.0836V21.0836ZM30.228 21.0836C30.228 22.1825 28.6795 23.0714 26.7731 23.0714C24.8657 23.0714 23.3183 22.1838 23.3183 21.0836C23.3183 19.9871 24.8657 19.097 26.7731 19.097C28.6795 19.0983 30.228 19.9871 30.228 21.0836ZM14.6817 21.0836C14.6817 22.1825 13.1343 23.0714 11.2269 23.0714C9.31952 23.0714 7.77313 22.1838 7.77313 21.0836C7.77313 19.9871 9.31952 19.097 11.2269 19.097C13.1343 19.0983 14.6817 19.9871 14.6817 21.0836Z"
        fill="white"
      />
    </svg>
  )
}

export const GraduateHat = () => {
  return (
    <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.6907 13.9998H12.3082C12.3082 13.9998 6.73221 20.3402 6.73221 24.5672C6.73221 28.7941 18.9994 36.1913 18.9994 36.1913C18.9994 36.1913 31.2667 28.7941 31.2667 24.5672C31.2667 20.3402 25.6907 13.9998 25.6907 13.9998Z"
        fill="#31373D"
      />
      <path
        d="M20.8284 0.557959C19.8236 -0.185986 18.1775 -0.185986 17.1727 0.557959L0.753599 12.7063C-0.2512 13.4502 -0.2512 14.6676 0.753599 15.4115L17.1716 27.5587C18.1775 28.3027 19.8225 28.3027 20.8273 27.5587L37.2453 15.4115C38.2512 14.6676 38.2512 13.4502 37.2453 12.7063L20.8284 0.557959Z"
        fill="#292F33"
      />
      <path
        d="M20.8284 0.557959C19.8236 -0.185986 18.1775 -0.185986 17.1727 0.557959L0.753599 12.7063C-0.2512 13.4502 -0.2512 14.6676 0.753599 15.4115L17.1716 27.5587C18.1764 28.3027 19.8225 28.3027 20.8273 27.5587L37.2464 15.4115C38.2512 14.6676 38.2512 13.4502 37.2464 12.7063L20.8284 0.557959Z"
        fill="#394146"
      />
      <path
        d="M7.84738 25.6238C7.84738 25.6238 5.61697 27.7373 5.61697 28.794V35.1344C5.61697 35.1344 5.61697 37.2479 7.84738 37.2479C10.0778 37.2479 10.0778 35.1344 10.0778 35.1344V28.794C10.0778 27.7373 7.84738 25.6238 7.84738 25.6238Z"
        fill="#FCAB40"
      />
      <path
        d="M7.84738 29.8508C9.69511 29.8508 11.193 28.4315 11.193 26.6806C11.193 24.9297 9.69511 23.5104 7.84738 23.5104C5.99965 23.5104 4.50177 24.9297 4.50177 26.6806C4.50177 28.4315 5.99965 29.8508 7.84738 29.8508Z"
        fill="#FDD888"
      />
      <path
        d="M7.84851 27.7373C7.23292 27.7373 6.7333 27.2649 6.7333 26.6806V22.498C6.68647 21.828 6.94185 20.3465 8.20203 19.4503L18.2634 11.0915C18.7273 10.7058 19.4321 10.7502 19.8369 11.1898C20.2429 11.6283 20.196 12.2962 19.7332 12.6809L9.60718 21.0904C8.9336 21.5723 8.9626 22.4346 8.9626 22.443L8.96483 26.6806C8.96483 27.2649 8.46522 27.7373 7.84851 27.7373V27.7373Z"
        fill="#FCAB40"
      />
      <path
        d="M18.9994 16.1132C20.8472 16.1132 22.3451 14.6938 22.3451 12.9429C22.3451 11.1921 20.8472 9.77271 18.9994 9.77271C17.1517 9.77271 15.6538 11.1921 15.6538 12.9429C15.6538 14.6938 17.1517 16.1132 18.9994 16.1132Z"
        fill="#31373D"
      />
    </svg>
  )
}

export const ADN = () => {
  return (
    <svg viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.58733 24.8552L5.66002 28.1248L4.83365 29.0032L1.76096 25.7345L2.58733 24.8552Z"
        fill="#77B255"
      />
      <path
        d="M5.58036 28.0382L8.65216 31.3078L7.82579 32.1871L4.75488 28.9175L5.58036 28.0382Z"
        fill="#FFD983"
      />
      <path
        d="M5.50066 22.9413L8.57335 26.2109L7.74698 27.0902L4.67429 23.8206L5.50066 22.9413Z"
        fill="#FFAC33"
      />
      <path
        d="M7.94037 25.5374L11.0131 28.807L10.1867 29.6863L7.1149 26.4167L7.94037 25.5374ZM11.4401 16.6213L14.5119 19.8909L13.6856 20.7702L10.6138 17.5007L11.4401 16.6213Z"
        fill="#EA596E"
      />
      <path
        d="M13.879 19.2183L16.9516 22.4869L16.1253 23.3662L13.0526 20.0976L13.879 19.2183Z"
        fill="#FFAC33"
      />
      <path
        d="M13.2871 13.548L16.3598 16.8176L15.5344 17.6969L12.4617 14.4273L13.2871 13.548Z"
        fill="#77B255"
      />
      <path
        d="M16.2479 16.6975L19.3206 19.9671L18.4943 20.8464L15.4216 17.5768L16.2479 16.6975Z"
        fill="#FFD983"
      />
      <path
        d="M15.6561 11.0272L18.7288 14.2968L17.9025 15.1752L14.8298 11.9065L15.6561 11.0272Z"
        fill="#FFAC33"
      />
      <path
        d="M18.6169 14.1777L21.6896 17.4463L20.8632 18.3257L17.7914 15.0561L18.6169 14.1777ZM21.7183 4.57666L24.791 7.84624L23.9646 8.7246L20.8919 5.45598L21.7183 4.57666Z"
        fill="#EA596E"
      />
      <path
        d="M24.6791 7.72717L27.7518 10.9958L26.9254 11.8751L23.8527 8.60554L24.6791 7.72717Z"
        fill="#FFAC33"
      />
      <path
        d="M24.0694 1.99683L27.142 5.2664L26.3157 6.14572L23.243 2.87614L24.0694 1.99683Z"
        fill="#77B255"
      />
      <path
        d="M27.066 5.1864L30.1387 8.45503L29.3123 9.33435L26.2396 6.06572L27.066 5.1864Z"
        fill="#FFD983"
      />
      <path
        d="M22.1973 0.624955C19.9411 3.02569 19.8686 6.47914 20.0521 9.96688C20.5006 9.99356 20.9492 10.025 21.3968 10.0564C21.746 10.0812 22.0907 10.105 22.4345 10.1269C22.2572 6.99644 22.2563 4.12603 23.8724 2.40646C24.5233 1.73673 24.7274 0.831686 24.2726 0.342964C23.7936 -0.171481 22.892 -0.130516 22.1973 0.624955V0.624955Z"
        fill="#9266CC"
      />
      <path
        d="M11.6541 24.2874L11.5171 21.7533C11.4939 21.3294 11.4652 20.8969 11.4509 20.4843C11.1859 20.2024 9.24305 20.1519 9.07562 20.33C9.08726 20.7492 9.1177 21.1722 9.13919 21.5942C9.16426 22.0725 9.252 23.7615 9.27259 24.1283C9.32094 24.9953 9.35854 25.8489 9.34959 26.6586C9.58147 26.9054 11.4724 27.0749 11.7177 26.813C11.7311 25.9822 11.698 25.1353 11.6541 24.2874V24.2874Z"
        fill="#3B88C3"
      />
      <path
        d="M11.7204 26.812L9.34869 26.6577C9.32541 28.7622 9.00489 30.6065 7.83831 31.8479C7.37543 32.3404 7.37543 33.1378 7.83831 33.6303C8.0693 33.8761 8.3728 34 8.67542 34C8.97803 34 9.28154 33.8771 9.51253 33.6303C11.6819 31.323 11.7213 26.7959 11.7204 26.812V26.812Z"
        fill="#55ACEE"
      />
      <path
        d="M22.584 12.662C22.1355 12.6353 21.686 12.6039 21.2384 12.5725C20.8901 12.5477 20.5445 12.5239 20.2016 12.502C20.3789 15.6315 20.3798 18.5028 18.7629 20.2224C17.1477 21.9411 14.4537 21.942 11.5162 21.7534C11.1706 21.7315 9.58327 21.62 9.1383 21.5933C5.8552 21.3971 2.60524 21.4733 0.346372 23.8769C-0.115607 24.3685 -0.115607 25.1659 0.347268 25.6584C0.578257 25.9042 0.881767 26.028 1.18438 26.028C1.487 26.028 1.7905 25.9051 2.02149 25.6584C3.63753 23.9398 6.3324 23.9379 9.2717 24.1265C9.61639 24.1484 11.2065 24.2599 11.6532 24.2865C14.9318 24.4818 18.1791 24.4056 20.4371 22.0039C22.6942 19.6032 22.7676 16.1497 22.584 12.662V12.662Z"
        fill="#9266CC"
      />
      <path
        d="M22.584 12.662C22.559 12.1847 22.4542 10.4927 22.4336 10.1279C22.4094 9.69916 22.3898 9.27617 22.3763 8.8589C22.0755 8.83889 20.4962 8.73219 20.0011 8.70361C20.0127 9.12279 20.0297 9.54483 20.0512 9.96686C20.0763 10.4441 20.181 12.1361 20.2016 12.5019C20.2509 13.3727 20.2831 14.2206 20.2741 15.0322C20.5723 15.0522 22.1489 15.158 22.6422 15.1866C22.6557 14.3558 22.6288 13.5108 22.584 12.662V12.662Z"
        fill="#744EAA"
      />
      <path
        d="M31.7404 8.26067C31.3643 7.8453 30.5004 7.68811 29.6892 8.59696C28.0732 10.3165 25.3765 10.3175 22.4336 10.1279C22.0898 10.106 20.4998 9.99358 20.0512 9.96691C16.7744 9.77161 13.528 9.84973 11.2718 12.2505C9.28872 14.3606 8.99326 17.2844 9.07563 20.3291C9.57074 20.3577 11.1519 20.4644 11.4509 20.4835C11.3631 17.8589 11.5521 15.5162 12.9469 14.032C14.563 12.3124 17.2605 12.3124 20.2025 12.501C20.5454 12.5229 22.1364 12.6344 22.5849 12.6611C25.8627 12.8564 29.1082 12.7792 31.3643 10.3785C32.1576 9.55059 32.1164 8.67604 31.7404 8.26067V8.26067Z"
        fill="#55ACEE"
      />
    </svg>
  )
}
